import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const PompyLoga = () => {
  return (
    <div>
      <div className="pompyElement">
        <StaticImage
          src="../../assets/images/pompy/pompy.png"
          alt="pompy"
          placeholder="tracedSVG"
          className="pompy"
        />
        <StaticImage
          src="../../assets/images/pompy/loga.png"
          alt="pompy"
          placeholder="tracedSVG"
          className="pompyLoga"
        />
      </div>
    </div>
  )
}

export default PompyLoga
