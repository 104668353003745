import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Ogrzewanie = () => {
  return (
    <div id="ogrzewanie">
      <div className="pompyElement">
        <StaticImage
          src="../../assets/images/pompy/ogrzewanie.png"
          alt="ogrzewanie"
          placeholder="tracedSVG"
          className="elementBackground"
        />
        <div className="text-holder" lang="en">
          <h3>SERWIS</h3>
          <p>
            Nasi specjaliści wykonują przeglądy i serwis pomp ciepła: kontrolę
            ciśnienia układu chłodniczego, kontrolę szczelności, sprawdzanie
            filtrów, zaworów, przyłączy. Prawidłowy serwis to podstawa
            długotrwałej, bezawaryjnej pracy urządzenia.
          </p>
        </div>
        <div className="text-holder2" lang="en">
          <h3>OGRZEWANIE</h3>
          <p>
            Pompy ciepła powietrze-woda pozwalają na zrównoważone działanie,
            zapewniające możliwość ogrzewania, chłodzenia, a także dostarczania
            ciepłej wody użytkowej. Pompa ciepła jest urządzeniem, które
            charakteryzuje się najwyższą klasą energetyczną wśród urządzeń
            grzewczych oraz najwyższą klasą wśród urządzeń do podgrzewania wody
            użytkowej. To rozwiązanie grzewcze przyszłości – zwłaszcza jeśli{" "}
            pozyskujemy energię pochodzącej ze źródeł odnawialnych. Wybierz
            swoją przyszłość!
          </p>
        </div>
      </div>
    </div>
  )
}

export default Ogrzewanie
