import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Montaz = () => {
  return (
    <div id="montaz_pompy">
      <div className="pompyElement">
        <StaticImage
          src="../../assets/images/pompy/montaz.png"
          alt="montaz"
          placeholder="tracedSVG"
          className="elementBackground"
        />
        <div className="text-holder" lang="en">
          <h3>MONTAŻ POMPY CIEPŁA</h3>
          <p>
            Pierwszym krokiem jest oszacowanie zapotrzebowania cieplnego dla
            budynku (audyt). Kolejnym zaś dobór pompy ciepła z odpowiednią mocą
            grzewczą. Następnie wybór miejsce montażu jednostek.
            <br />
            Nasza oferta obejmuje instalację pompy ciepła oraz jej podłączenie
            do systemu centralnego ogrzewania i wymiennika wody użytkowej.
            komfortu!
          </p>
        </div>
      </div>
    </div>
  )
}

export default Montaz
