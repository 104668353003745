import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { Link } from "gatsby"

import SideMenu from "../SideMenu"

import "../../assets/css/pompy.css"

const Menu = () => {
  return (
    <>
      <div className="menu">
        <AnchorLink
          to="/pompy_ciepla#pompa_ciepla"
          className="menuElement fourElementMenu"
        >
          <StaticImage
            src="../../assets/images/pompy/menu/pompa_ciepla.png"
            alt="pompa_ciepla"
            placeholder="tracedSVG"
          />
          <h3>pompa ciepla</h3>
        </AnchorLink>
        <AnchorLink
          to="/pompy_ciepla#budowa_pompy"
          className="menuElement fourElementMenu"
        >
          <StaticImage
            src="../../assets/images/pompy/menu/budowa_pompy.png"
            alt="budowa_pompy"
            placeholder="tracedSVG"
          />
          <h3>budowa pompy</h3>
        </AnchorLink>
        <AnchorLink
          to="/pompy_ciepla#montaz_pompy"
          className="menuElement fourElementMenu"
        >
          <StaticImage
            src="../../assets/images/pompy/menu/montaz.png"
            alt="montaz"
            placeholder="tracedSVG"
          />
          <h3>montaz</h3>
        </AnchorLink>
        <AnchorLink
          to="/pompy_ciepla#ogrzewanie"
          className="menuElement fourElementMenu"
        >
          <StaticImage
            src="../../assets/images/pompy/menu/ogrzewanie.png"
            alt="ogrzewanie"
            placeholder="tracedSVG"
          />
          <h3>ogrzewanie</h3>
        </AnchorLink>
      </div>
      <SideMenu base="pompy_ciepla#pompy_ciepla">
        <div className="sideMenuElement">
          <h3>pompa ciepla</h3>
          <AnchorLink to="/pompy_ciepla#pompa_ciepla">
            <StaticImage
              src="../../assets/images/pompy/menu/pompa_ciepla.png"
              alt="pompa_ciepla"
              placeholder="tracedSVG"
            />
          </AnchorLink>
        </div>
        <div className="sideMenuElement">
          <h3>budowa pompy</h3>
          <AnchorLink to="/pompy_ciepla#budowa_pompy">
            <StaticImage
              src="../../assets/images/pompy/menu/budowa_pompy.png"
              alt="budowa_pompy"
              placeholder="tracedSVG"
            />
          </AnchorLink>
        </div>
        <div className="sideMenuElement">
          <h3>montaz</h3>
          <AnchorLink to="/pompy_ciepla#montaz_pompy">
            <StaticImage
              src="../../assets/images/pompy/menu/montaz.png"
              alt="montaz"
              placeholder="tracedSVG"
            />
          </AnchorLink>
        </div>
        <div className="sideMenuElement">
          <h3>ogrzewanie</h3>
          <AnchorLink to="/pompy_ciepla#ogrzewanie">
            <StaticImage
              src="../../assets/images/pompy/menu/ogrzewanie.png"
              alt="ogrzewanie"
              placeholder="tracedSVG"
            />
          </AnchorLink>
        </div>
        <Link className="sideMenuOZEBack" to="/ekologiczna_energia">
          <StaticImage
            src="../../assets/images/oze/oze_back.png"
            alt="sterowanie"
            placeholder="tracedSVG"
          />
        </Link>
      </SideMenu>
    </>
  )
}

export default Menu
