import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const BudowaPompy = () => {
  return (
    <div id="budowa_pompy">
      <div className="title">
        <h3>BUDOWA POMPY</h3>
      </div>
      <StaticImage
        src="../../assets/images/pompy/budowa_pompy.png"
        alt="budowa_pompy"
        placeholder="tracedSVG"
        className="budowaPompy"
        layout="constrained"
      />
      <div className="pompyElement">
        <StaticImage
          src="../../assets/images/pompy/monolit.jpg"
          alt="square"
          placeholder="tracedSVG"
          className="elementBackground"
        />
        <div className="text-holder" lang="en">
          <p>
            Pompa ciepła to nowoczesne rozwiązanie, gwarantujące uzyskanie
            optymalnych warunków bytowych w Twoim domu. W zależności od pogody,
            pozwala wykorzystać funkcję grzania lub chłodzenia. Oferujemy pompy
            typu monoblok i split.
          </p>
          <p>
            <b>MONOBLOK</b> – to jedna jednostka montowana na zewnątrz budynku,
            w której w zaizolowanej termicznie oraz akustycznie konstrukcji
            znajdują się wszystkie podzespoły.
          </p>
          <p>
            <b>SPLIT</b> – typ pompy, w którym podzespoły są podzielone pomiędzy
            jednostką zewnętrzną a wewnętrzną.Wewnątrz montowany jest skraplacz
            oraz automatyka i część hydrauliczna. Z kolei parownik, sprężarka,
            zawór rozprężny oraz wentylator znajdują się w jednostce
            zewnętrznej. Sprawia to, że sterowanie pompą jest wygodniejsze.
          </p>
        </div>
      </div>
    </div>
  )
}

export default BudowaPompy
