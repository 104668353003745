import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
//My Components
import Top from "../components/Top"
import ContactForm from "../components/ContactForm"
import Footer from "../components/Footer"
import Menu from "../components/pompy/Menu"
import PompaCiepla from "../components/pompy/PompaCiepla"
import BudowaPompy from "../components/pompy/BudowaPompy"
import Montaz from "../components/pompy/Montaz"
import Ogrzewanie from "../components/pompy/Ogrzewanie"
import PompyLoga from "../components/pompy/PompyLoga"
import Seo from "../components/Seo"

// Query for Top image and Contact Form background image
export const query = graphql`
  {
    form: file(
      name: { eq: "pompy" }
      sourceInstanceName: { eq: "formBackgrounds" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG)
      }
    }
    top: file(name: { eq: "pompy" }, sourceInstanceName: { eq: "topImages" }) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG)
      }
    }
  }
`

const Fotowoltaika = () => {
  const data = useStaticQuery(query)
  const imgForm = getImage(data.form)
  const imgTop = getImage(data.top)
  return (
    <div id="pompy_ciepla" className="page pompyPage">
      <Seo title="Pompy ciepła" />
      <Top image={imgTop} />
      <Menu />
      <PompaCiepla />
      <BudowaPompy />
      <Montaz />
      <Ogrzewanie />
      <PompyLoga />
      <ContactForm backgroundImage={imgForm} />
      <Footer />
    </div>
  )
}

export default Fotowoltaika
