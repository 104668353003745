import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const PompaCiepla = () => {
  return (
    <div id="pompa_ciepla">
      <div className="pompyElement">
        <StaticImage
          src="../../assets/images/pompy/pompa_ciepla.png"
          alt="pompa_ciepla"
          placeholder="tracedSVG"
          className="elementBackground"
        />
        <div className="text-holder" lang="en">
          <h3>POMPY CIEPŁA</h3>
          <p>
            Pompa ciepła to idealny wybór dla poszukujących ekologicznego źródła
            ogrzewania. Daje możliwość na współpracę z panelami
            fotowoltaicznymi. Pompa ciepła, zastosowana w domach dobrze
            wyizolowanych, z ogrzewaniem podłogowym, pozwala zaoszczędzić aż 75%
            kosztów energii oraz zredukować nawet do 30% emisję szkodliwych
            substancji.
          </p>
          <p className="p2">
            Pompy ciepła wykorzystują energię zmagazynowaną w powietrzu,
            gwarantując odpowiedni komfort cieplny w domu czy miejscu pracy.
            Sprawdza się przy ogrzewaniu podłogowym, kaloryferach, a także do
            ogrzewania wody użytkowej. Wybór pompy ciepła to wybór wygody i
            komfortu!
          </p>
        </div>
      </div>
    </div>
  )
}

export default PompaCiepla
